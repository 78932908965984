import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
// import { saveLists } from '@/shared/model/saveLists';
import { SearchParams } from '@/shared/model/searchParams';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { SaveList } from '@/shared/model/saveList';
import { ROUTES } from '@/router/routesEnum';
import { CONST } from '@/shared/utils/Constants';

const saveListModule = namespace('saveList');
const logger = new Logger('about');

@Component({
  components: { D4yTable },
})
export default class SaveLists extends Vue {
  @saveListModule.Getter('getSaveLists')
  private saveLists!: SaveList[];
  @saveListModule.Getter('getSaveListsTotal')
  private saveListsTotal!: number;
  @saveListModule.Getter('getSaveListsIsLoading')
  private saveListsIsLoading!: boolean;
  @saveListModule.Getter('getSaveListsSearchParams')
  private saveListsSearchParams!: SearchParams;

  @saveListModule.Action('getSaveLists')
  private actionGetSaveLists!: any;
  @saveListModule.Action('deleteSaveList')
  private actionDeleteSaveList!: any;

  private image = {
    src: 'img_employee.svg',
    header: this.$t('pdf-ausgaben'),
    height: 250,
    width: 445,
  };

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('title'), value: 'title' },
      { text: this.$t('type'), value: 'type' },
      { text: this.$t('product_groups'), value: 'warengruppen', width: '200px' },
      { text: this.$t('created_at'), value: 'createdAt' },
      { text: this.$t('created_on'), value: 'createdOn' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  private async saveListsUpdateOptions(newVal: any, oldVal: any) {
    this.saveListsSearchParams.dataOption = newVal;
    this.saveListsSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.saveListsIsLoading) this.getSaveLists();
  }
  private async getSaveLists() {
    await this.actionGetSaveLists({ searchParams: this.saveListsSearchParams });
  }

  private getFirstChars(value: string, count: number) {
    var dots = '';
    if (value.length > count) {
      dots = '...';
    }
    return `${value.slice(0, count)}${dots}`;
  }

  async created() {
    var promiseAll = [this.actionGetSaveLists()];
    await Promise.all(promiseAll);
  }

  private addSaveList() {
    logger.debug('addsaveList');

    this.$router.push({
      name: ROUTES.list,
      params: { saveListId: '' },
    });
  }

  private editSaveList(item: SaveList) {
    logger.debug('editSaveList');

    this.$router.push({
      name: ROUTES.list,
      params: { saveListId: item.id },
    });
  }

  private async deleteSaveList(item: SaveList) {
    logger.debug('onDelete');
    this.$confirm
      .open(
        `${this.$t('dialog_delete.title')}`,
        `${this.$t('dialog_delete.message', {
          0: item.name,
        })}`
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteSaveList(item.id);
          await this.actionGetSaveLists();
        }
      });
  }

  //#region ReportDialogLogic
  dialogReport = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogReport.show = false;
    },
  };

  private showSuppliersDialog(item: any) {
    this.dialogReport.show = true;
    this.dialogReport.model = item;
  }
  //#endregion
}
