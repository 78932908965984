import { Logo } from './logo';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { email } from 'vee-validate/dist/rules';

@Component({ name: 'login-new', components: {} })
export default class LoginNew extends Vue {
  public showPassword = false;
  public user = '';
  public usernamePwdForgotten = '';
  public password = '';
  public email = '';
  public emailSent = false;
  rememberMe = false;

  @Prop({ default: true, type: Boolean })
  private rounded!: boolean;

  @Prop({ default: '' })
  public pathToLogo?: string;

  @Prop({
    default: () => {
      return {
        show: false,
        path: 'https://cdn.vuetifyjs.com/images/logos/vuetify-logo-light.png',
        alt: 'logo',
        width: '60px',
      };
    },
  })
  private logo!: Logo;

  @Prop({ default: '' })
  public loginTitleText?: string;

  @Prop({ default: '' })
  public pwdForgottenTitleText?: string;

  @Prop({ default: false })
  public isLoading?: boolean;

  @Prop({ default: false })
  public passwordForgotten?: boolean;

  @Prop({ default: false })
  public showPwdStrength?: boolean;

  @Prop({ default: null })
  public isUniqueEmail?: boolean | null; // Is there only 1 record in database with such an Email

  @Prop({ default: '' })
  public passwordForgottenInfoText?: string;

  @Prop({ default: '' })
  public passwordForgottenHelpText?: string;

  private showPasswordForgotten = false;

  private loading: boolean = false;

  @Ref('observer-login-form')
  private observerLoginForm!: any;

  @Ref('observer-pw-forgotten-form')
  private observerPwForgottenForm!: any;

  @Watch('isLoading')
  public onLoaderChange() {
    this.loading = this.isLoading || false;
  }

  /**
   * Show username field for `reset password` if : 1) email NOT unique 2) email field has value 3) email was not sent
   */
  get showResetPwdUsername() {
    return !this.isUniqueEmail && this.isUniqueEmail !== null && this.email && !this.emailSent;
  }

  public async login() {
    const result = await this.observerLoginForm.validate();

    if (result) {
      this.loading = true;

      const credentialPayload = {
        username: this.user,
        password: this.password,
        rememberMe: this.rememberMe,
      };
      this.$emit('login', credentialPayload);
    }
  }

  private updatePassword(value: string) {
    this.password = value;
  }

  @Watch('passwordForgotten')
  public onPasswordForgottenChanged(val: any, old: any) {
    this.showPasswordForgotten = val;
  }

  public showPasswordForgottenView() {
    this.showPasswordForgotten = true;
  }

  public passwordFogottenCancel() {
    this.resetPwdForgottenFields();
  }

  public backToLogin() {
    this.emailSent = false;
    this.resetPwdForgottenFields();
  }

  private resetPwdForgottenFields() {
    this.showPasswordForgotten = false;
    this.usernamePwdForgotten = '';
    this.email = '';
  }

  public onKeyup() {
    // TODO: improve this part to reduce the amount of checks/requests
    const isValidEmail = email.validate(this.email);

    if (isValidEmail) {
      this.$emit('check-IsEmailUnique', this.email);
    }
  }

  private async emitPasswordForgotten() {
    const result = await this.observerPwForgottenForm.validate();

    // TODO: add custom validator that doesn't allow to proceed unless Email uniqueness was check on the backend (when `this.isUniqueEmail === null`)
    if (result && this.isUniqueEmail !== null) {
      if (this.isUniqueEmail) {
        this.$emit('passwordForgotten', this.email);
        this.emailSent = true;
      } else if (!this.isUniqueEmail) {
        this.$emit('passwordForgotten', this.email, this.usernamePwdForgotten);
        this.emailSent = true;
      }
    }
  }
}
