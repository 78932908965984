var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('d4y-table',{attrs:{"allowAdd":true,"allowEdit":true,"allowDelete":true,"haveMoreActions":true,"serverItemsLength":_vm.saveListsTotal,"items":_vm.saveLists.items,"image":_vm.image,"headers":_vm.headers,"options":_vm.saveListsSearchParams.dataOption,"isFirstNameAsRef":true},on:{"click:delete":_vm.deleteSaveList,"action:add":_vm.addSaveList,"click:avatar":_vm.editSaveList,"click:edit":_vm.editSaveList,"click:row":_vm.editSaveList,"update:options":_vm.saveListsUpdateOptions},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("type." + (item.type))))+" ")]}},{key:"warengruppen",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.warengruppen}},[_vm._v(" "+_vm._s(_vm.getFirstChars(item.warengruppen, 50)))])]}},{key:"createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateWithLocale")(item.createdAt,_vm.$i18n.locale))+" ")]}},{key:"more-actions",fn:function(ref){
var item = ref.item;
return [_c('d4y-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.showSuppliersDialog(item.item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }