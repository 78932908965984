import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import UserMini from '@/components/user/mini/user-mini.vue';
import dataOptions, { DataOptions } from '@/shared/model/DataOptions';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import TreeNavigator from '@/components/tree-navigator/tree-navigator.vue';
import { TranslateResult } from 'vue-i18n';
const logger = new Logger('components.position-list');
const dateUtils = DateUtils;

@Component({ name: 'd4y-edit', components: {} })
export default class D4yEdit extends Vue {
  @Prop({ default: 'white' })
  private color?: string;

  @Prop({ default: false, type: Boolean })
  private isDense!: boolean;
  @Prop({ default: 135, type: Number })
  private denseHeight!: number;

  @Prop({
    default: () => {
      return {
        src: '', // avoid `asset not found` error
        header: '',
        height: 200,
        width: 360,
      };
    },
  })
  private image!: {
    src: string;
    header: string;
    height: number;
    width: number;
  };

  @Prop({ default: false, type: Boolean })
  private isRightHeaderFlexColumn!: boolean;

  mounted() {
    if (this.isDense && !this.image.src) {
      this.image!.height = this.denseHeight;
    }
  }

  /**
   * Check if named slot used in the `d4y-edit` implementation in parent components (like `zr-document`[groups_list in Sketch], etc)
   * @param name (name of the slot)
   */
  private hasSlot(name: string) {
    return !!this.$slots[name] || !!this.$scopedSlots[name];
  }

  // TODO: Move this method in global space (probably try to mixin/helper or something like that )
  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }
}
