/**
 * Collection of helpful methods for views
 */
import store from '@/shared/store';

export interface ViewHelper {
  /**
   * Shows snackbar on top of the page with given message
   * @param (message) contains the message that will be shown inside the snackbar
   * @param (duration) if given contains the duration how long the snackbar should be shown
   */
  showSnackbarSuccess: (message: string, duration?: number) => void;

  /**
   * Shows snackbar on top of the page with given message
   * @param (message) contains the message that will be shown inside the snackbar
   * @param (duration) if given contains the duration how long the snackbar should be shown
   */
  showSnackbarError: (message: string, duration?: number) => void;
}

export const DefaultViewHelper: ViewHelper = {
  showSnackbarSuccess(message: string, duration?: number) {
    store.commit('showSuccess', message, { root: true });
  },
  showSnackbarError(message: string, duration?: number) {
    store.commit('showError', message, { root: true });
  },
};
