import { Component, Vue } from 'vue-property-decorator';
import LoginNew from '@/components/_common/login-new/LoginNew.vue';
import { ActionMethod } from 'vuex';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { ROUTES } from '@/router/routesEnum';
import { DefaultViewHelper } from '../ViewHelper';
// import AuthorityUtils from '@/router/authorityUtils';

const logger = new Logger('login');
const authModule = namespace('auth');

@Component({
  components: {
    LoginNew,
  },
})
export default class Login extends Vue {
  private passwordForgotten = false;
  private isLoading = false;

  private isUniqueEmail: boolean | null = null;

  private logoObj: any = {
    show: true,
    path: require('@/assets/logo.png'),
    alt: 'vuetify-logo',
    width: '110px',
  };

  @authModule.Action('login') private login!: ActionMethod;
  @authModule.Action('loadAccountDetails')
  private loadAccountDetails!: ActionMethod;
  @authModule.Action('resetPasswordByEmail')
  private resetPasswordByEmailAction!: ActionMethod;
  @authModule.Action('isUniqueEmail')
  private actionIsUniqueEmail!: any;

  /**
   * Initialize component with route parameter
   */
  async mounted() {
    this.passwordForgotten = !!this.$route.params!.passwordForgotten;

    // await AuthorityUtils.confirmEmailIfUrl(this.$route.query);
  }

  /**
   * Check if email unique and set the component `isUniqueEmail` field with result
   * @param inputEmail the User's email
   */
  private checkIsEmailUnique(inputEmail: string) {
    this.actionIsUniqueEmail({ email: inputEmail })
      .then((result: any) => {
        if (result > 1) {
          this.isUniqueEmail = false;
        } else {
          this.isUniqueEmail = true;
        }
      })
      .catch((err: any) => {
        logger.error('checkIsEmailUnique :>> ', err);
      });
  }

  /**
   * Handle authentication callback.
   * @param credentials the authentication credentials
   */
  private async emitLogin(credentials: any) {
    this.isLoading = true;
    let redirect = this.$route.query.redirect as string;

    await this.login(credentials)
      .then(async () => {
        await this.loadAccountDetails();

        logger.debug(redirect);
        if (redirect && redirect != '/') {
          this.$router.push({ path: redirect });
        } else {
          this.$router.push({ name: ROUTES.home });
        }
      })
      .catch((e: any) => {
        logger.error('Login/emitLogin error', e);
        // Show snackbar error when login failed
        DefaultViewHelper.showSnackbarError(this.$i18n.tc('error.login_failed'));
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * sends the given email to backend to initiate the password reset process
   * @param email
   */
  private emitPasswordForgotten(email: string, username: string) {
    const payload = { email, username };
    this.resetPasswordByEmailAction(payload).then(() => {
      this.passwordForgotten = false;
    });
  }
}
